<template>
    <div class="paper-manage">
        <div class="question-type-tabs">
            <el-tabs v-model="currentTypeTab" type="card" @tab-click="tabChange">
                <el-tab-pane label="理论"></el-tab-pane>
                <el-tab-pane label="实操"></el-tab-pane>
            </el-tabs>
        </div>
        <div class="theory-box">
            <div class="create-paper-box">
                <div class="select-box">
                    <span class="text">试卷等级</span>
                    <el-select v-model="getPaperTableParams.grade_id" clearable placeholder="请选择" @change="paper">
                        <el-option v-for="item in paperLevelList" :key="item.value" :label="item.label"  :value="item.value">
                        </el-option>
                    </el-select>
                </div>
                <el-button type="primary" @click="typeFun.createButton">
                    <i class="el-icon-plus" style="margin-right: 5px"></i>创建试卷
                </el-button>
            </div>
        </div>
        <div class="scroll-content">
            <el-scrollbar style="height: 100%">
                <TableList :tableColumnConfig="tableConfig" :tableData="tableData">
                    <template #default="{ item }">
                        <div>
                            <el-link type="success" :underline="false" @click="typeFun.tableView(item)">查看</el-link>
                            <el-link v-if="item.type == 2" type="success" :underline="false" @click="typeFun.tableEdit(item)"
                                >编辑</el-link
                            >
                            <el-link v-if="item.type == 2" type="danger" :underline="false" @click="typeFun.tableDelete(item)"
                                >删除</el-link
                            >
                        </div>
                    </template>
                </TableList>
            </el-scrollbar>
        </div>
        <div class="paging-box">
            <el-pagination
                @current-change="pagingChange"
                :current-page.sync="getPaperTableParams.page"
                :page-size="getPaperTableParams.pageSize"
                layout="prev, pager, next, jumper"
                :total="totalCount"
            >
            </el-pagination>
        </div>
        <Dialog ref="createPaper" title="创建试卷" :isShowButton="[false, false]" themeColor="28, 176, 100">
            <div class="dialog-create-paper-box">
                <el-button type="primary" @click="artificialPaper">手动创建试卷</el-button>
                <el-button type="primary" @click="randomPaper">规则组卷</el-button>
            </div>
        </Dialog>
        <Dialog ref="deletePaper" title="删除试卷" :confirmHandler="confirmDeletePaper" themeColor="28, 176, 100">
            <div>删除后不可恢复</div>
        </Dialog>
    </div>
</template>

<script>
import Vue from "vue";
import TableList from "components/admin/common/TableList";
import Dialog from "components/admin/common/Dialog";
import {
    getIdentifyCenterTheoryPaperList,
    deleteIdentifyCenterTheoryPaper,
    deleteIdentifyCenterActualPaper,
    studentlevel,
    getIdentifyCenterActualPaperList,
} from "@/utils/apis";

export default {
    name: "Index",
    data() {
        return {
            currentTypeTab: 0,
            paperLevelList: [],
            tableData: [],
            getPaperTableParams: {
                paging: "1",
                page: 1,
                pageSize: 10,
                grade_id: "",
                level: 0,
                id: 0,
            },
            theoryParamsKey: ["paging", "page", "pageSize", "grade_id"],
            actualParamsKey: ["paging", "page", "pageSize", "level", "id"],
            totalCount: 0,
            deleteParams: {},
        };
    },
    components: {
        TableList,
        Dialog,
    },
    watch: {
        "getPaperTableParams.grade_id"(newVal) {
            this.getPaperTableParams.level = newVal;
            this.getPaperList();
        },
    },

    computed: {
        tableConfig() {
            return [
                { label: "试卷名称", prop: "name" },
                { label: "创建人", prop: this.currentTypeTab * 1 ? "type_name" : "user_name" },
                { label: "操作", align: "center", width: 200 },
            ];
        },
        getListDataParams() {
            let { currentTypeTab, getPaperTableParams, actualParamsKey, theoryParamsKey, getPaperTableParams: params } = this;
            let obj = {};
            let keys = currentTypeTab * 1 ? actualParamsKey : theoryParamsKey;
            keys.forEach((item) => params[item] && this.$set(obj, item, params[item]));
            return obj;
        },
        typeFun() {
            let { currentTypeTab: tabIndex } = this;
            tabIndex *= 1;
            return {
                getListData: tabIndex ? getIdentifyCenterActualPaperList : getIdentifyCenterTheoryPaperList,
                createButton: tabIndex ? this.actualCreate : this.theoryCreate,
                tableView: tabIndex ? this.actualView : this.theoryView,
                tableEdit: tabIndex ? this.actualEdit : this.theoryEdit,
                tableDelete: tabIndex ? this.deleteActualPaper : this.deleteTheoryPaper,
                confirmDelete: tabIndex ? deleteIdentifyCenterActualPaper : deleteIdentifyCenterTheoryPaper,
            };
        },
    },
    methods: {
        async getPaperList() {
            let res = await this.typeFun.getListData(this.getListDataParams);
            if (res.code == 200) {
                this.tableData = res.data.list;
                this.totalCount = res.data.total;
            } else {
                this.$message.error(res.msg);
            }
        },
        async getPaperLevelData() {
            let res = await studentlevel();
            if (res.code == 200) {
                this.paperLevelList = res.data.map((item) => ({ value: item.grade_id, label: item.level_name }));
            } else {
                this.$message.error(res.msg);
            }
        },
        paper(){
            this.getPaperTableParams.page = 1  
        },
        pagingChange(val) {
            this.getPaperTableParams.page = val;
            this.getPaperList();
        },
        theoryCreate() {
            this.$refs.createPaper.open();
        },
        actualCreate() {
            this.$router.push("/examPaperManage/actualPaper");
        },
        tabChange(index) {
            this.getPaperTableParams.grade_id = "";
            this.getPaperTableParams.page = 1;
            this.getPaperList();
        },
        artificialPaper(item) {
            this.$router.push("/examPaperManage/artificialPaper");
        },
        theoryEdit(item) {
            this.$router.push({
                path: "/examPaperManage/artificialPaper",
                query: {
                    id: item.check_center_paper_id,
                },
            });
        },
        actualEdit(item) {
            if (item.editdisable) {
                return this.$message.warning('无法编辑：已有一场正在进行中的考试使用这份试卷！');
            }
            this.$router.push({
                path: "/examPaperManage/actualPaper",
                query: {
                    id: item.id,
                },
            });
        },
        randomPaper() {
            this.$router.push("/examPaperManage/randomPaper");
        },
        theoryView(item) {
            this.$router.push({
                path: "/examPaperManage/TheoryPaperDetail",
                query: {
                    id: item.check_center_paper_id,
                },
            });
        },
        actualView(item) {
            this.$router.push({
                path: "/examPaperManage/ActualPaperDetail",
                query: {
                    id: item.operation_paper_id,
                    type: item.type,
                },
            });
        },
        deleteTheoryPaper(item) {
            this.deleteParams = { check_center_paper_id: item.check_center_paper_id };
            this.$refs.deletePaper.open();
        },
        deleteActualPaper(item) {
            this.deleteParams = { id: item.id };
            this.$refs.deletePaper.open();
        },
        async confirmDeletePaper() {
            let res = await this.typeFun.confirmDelete(this.deleteParams);
            if (res.code == 200) {
                this.$message.success(res.msg);
                this.getPaperList();
            } else {
                this.$message.error(res.msg);
            }
            this.$refs.deletePaper.close();
        },
    },
    created() {
        let tabIndex = sessionStorage.getItem("paperTabIndex");
        if (tabIndex) {
            this.currentTypeTab = tabIndex;
        }
    },
    mounted() {
        this.getPaperList();
        this.getPaperLevelData();
    },
    beforeRouteLeave(to, from, next) {
        if (to.meta.parentName) {
          let target = to.meta.parentName.length > 1 && to.meta.parentName.includes("examPaperManage");
          target ? sessionStorage.setItem("paperTabIndex", this.currentTypeTab) : sessionStorage.removeItem("paperTabIndex");
        }
        next();
    },
};
</script>

<style scoped lang="scss">
.question-type-active {
    background-color: #e7f6ef;
    color: #1cb064;
}
.paper-manage {
    display: flex;
    flex-direction: column;
    padding: 0 20px;
    height: 100%;
    color: #333;
    font-size: 16px;

    .create-paper-box {
        display: flex;
        justify-content: space-between;
        .text {
            margin-right: 10px;
        }
    }
    .theory-box {
        margin-bottom: 20px;
    }
    .question-type-tabs {
        display: flex;
        margin-bottom: 20px;
        ::v-deep .el-tabs__item {
            width: 170px;
            height: 50px;
            text-align: center;
            line-height: 50px;
            font-size: 15px;
        }
        ::v-deep .is-active {
            transition: all 0.3s;
            background-color: #e7f6ef;
        }
        .item {
            width: 150px;
            height: 50px;
            line-height: 50px;
            text-align: center;
            transition: all 0.3s;
            cursor: pointer;
        }
        .item:hover {
            background-color: #e7f6ef;
            color: #1cb064;
        }
    }
    .scroll-content {
        flex: 1;
        overflow: hidden;
    }
    .paging-box {
        text-align: center;
        padding: 20px 0;
    }
}
.dialog-create-paper-box {
    display: flex;
    justify-content: center;
    padding: 30px 0;
    ::v-deep .el-button {
        width: 120px;
        height: 40px;
        padding: 0;
    }
    ::v-deep .el-button:nth-child(1) {
        margin-right: 30px;
    }
}
::v-deep .el-scrollbar__wrap {
    overflow-x: hidden;
}
</style>
